import React from 'react'
import ReactDOM from 'react-dom/client'
import { NotificationProvider } from '@wallyhealth/web-core'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
// eslint-disable-next-line import/no-named-as-default
import { posthog } from 'posthog-js'
import { initMonitor } from './services/monitoringService'
import Router from './router'
import './polyfills'
import './index.css'
import '@wallyhealth/web-core/dist/fonts/index.css'

posthog.init('phc_7kPWGH2BoqFeCVcs3Dlzn3A67O25gUZYRMRYSURbMFs',
  {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always'
  }
)

initMonitor()

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
